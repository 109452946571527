const periods = [
  {
    label: "All time",
    value: "allTime",
  },
  {
    label: "Last 24hrs",
    value: "last24hrs",
  },
  {
    label: "Last Week",
    value: "lastWeek",
  },
  {
    label: "Last Month",
    value: "lastMonth",
  },
  {
    label: "Last Year",
    value: "lastYear",
  },
];
export default periods;
