import { routePaths } from "../paths.route";
export const DashboardRoutes = {
  path: "/portal",
  name: "DashboardLayout",
  meta: {
    requiresAuth: true,
  },
  component: () => import("@/layouts/DashboardLayout.vue"),
  children: [
    {
      path: routePaths["overview"],
      name: "DashboardPortal",
      component: () => import("@/views/dashboard/Index.vue"),
    },
    {
      path: routePaths["reports"],
      name: "ReportsAndAnalytics",
      component: () => import("@/views/dashboard/Reports.vue"),
    },
    {
      path: routePaths["customer_support"],
      name: "CustomerSupport",
      component: () => import("@/views/dashboard/CustomerSupport.vue"),
    },
    {
      path: routePaths["card_management"],
      name: "CardManagement",
      component: () => import("@/views/dashboard/CardManagement.vue"),
    },
    {
      path: routePaths["referral_tracking"],
      name: "ReferralTracking",
      component: () => import("@/views/dashboard/ReferralTracking.vue"),
    },
    {
      path: routePaths["security_measures"],
      name: "Security",
      component: () => import("@/views/dashboard/Security.vue"),
    },
  ],
};
