const BASE_PATH = "/portal";
export const routePaths = {
  // Dashboard
  overview: `${BASE_PATH}/overview`,
  reports: `${BASE_PATH}/reports`,
  customer_support: `${BASE_PATH}/customer-support`,
  card_management: `${BASE_PATH}/card-management`,
  referral_tracking: `${BASE_PATH}/referral-tracking`,
  security_measures: `${BASE_PATH}/security-measures`,
};
