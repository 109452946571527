import axiosService from "@/services/axiosService";
import router from "@/router";
const state = {};

const getters = {};

const mutations = {};

const actions = {
  async getAllUsers({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(
        `/cs/user?page=${payload.page || ""}&limit=${payload.limit || ""
        }&value=${payload.value}&field=${payload.field}`
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async getPresenceStats({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/cs/user/log-requests?${new URLSearchParams(payload).toString()}`, payload);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getUserInsights({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/cs/user/insights`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getUserOverview({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/cs/user/overview`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getUserDocs({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(
        `/cs/user/docs?page=${payload.page || ""}&limit=${payload.limit || ""}`
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getSingleUser({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/cs/user/${payload.id}`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async limitUser({ commit, state }, payload) {
    try {
      const { data } = await axiosService.put(`/cs/user/limit`, payload);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async searchUser({ commit, state }, payload) {
    try {
      const { data } = await axiosService.post(
        `/user/search?page=${payload.page || ""}&limit=${payload.limit || ""}`,
        payload
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async generatePassphrase({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/passphrase`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async addNewUser({ commit, state }, credentials) {
    try {
      const { data } = await axiosService.post("/cs/admin/add", credentials);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async removeUser({ commit, state }, credentials) {
    try {
      const { data } = await axiosService.post("/cs/user/remove", credentials);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
