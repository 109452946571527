import { createStore } from "vuex";
import createPersistedState from "vuex-plugin-persistedstate";
import {
  auth,
  resources,
  user,
  ticket,
  card,
  security,
  referrer,
  analytics,
} from "./modules";
const store = createStore({
  plugins: [createPersistedState()],
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    resources,
    user,
    ticket,
    card,
    security,
    referrer,
    analytics,
  },
});
export default store;
