import axiosService from "@/services/axiosService";
import router from "@/router";
const state = {};

const getters = {};

const mutations = {};

const actions = {
  async fetchPermissions({ commit, state }) {
    try {
      const { data } = await axiosService.get("/permissions");
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getNotice({ commit, state }) {
    try {
      const { data } = await axiosService.get("/cs/notice");
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async changeNotice({ commit, state }, payload) {
    try {
      const { data } = await axiosService.put("/cs/notice", payload);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
