<template>
  <div
    class="mz-card"
    :class="{
      'mz-card--large': size == 'large',
      'mz-card--small': size == 'small',
      'mz-card--smaller': size == 'smaller',
    }"
  >
    <div class="w-100 py-5">
      <div class="d-flex justify-content-center my-2">
        <img src="@/assets/brand/logo.svg" alt="" class="mz-logo" />
      </div>
      <div class="text-center">
        <h1 class="mz-text__h1 mz-text--dark">{{ title }}</h1>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "GenericCard",
  props: {
    size: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "Hello",
    },
  },
};
</script>

<style></style>
