<template>
  <div
    class="mz-tabs"
    :class="{
      'mz-tabs--auto': isAuto,
    }"
  >
    <a
      href="javascript:void(0)"
      class="mz-tabs__item"
      v-for="(item, index) in tabs"
      :key="index"
      @click="setActiveTab(item)"
      :class="[
        selectedTab &&
          selectedTab.value === item.value &&
          'mz-tabs__item--active',
      ]"
    >
      {{ item.name }}
    </a>
  </div>
</template>

<script>
export default {
  name: "DashboardTabs",
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    isFull: {
      type: Boolean,
      default: false,
    },
    isAuto: {
      type: Boolean,
      default: false,
    },
    persistTab: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectedTab: null,
    };
  },
  mounted() {
    if (this.persistTab) {
      const hashValue = window.location.hash.substring(1);
      if (hashValue) {
        const matchingTab = this.tabs.find((tab) => tab.value === hashValue);
        if (matchingTab) {
          this.setActiveTab(matchingTab);
        } else {
          this.setActiveTab(this.tabs[0]);
        }
      } else if (this.tabs.length > 0) {
        this.setActiveTab(this.tabs[0]);
      }
    } else {
      this.setActiveTab(this.tabs[0]);
    }
  },
  watch: {
    selectedTab(newTab) {
      window.location.hash = newTab.value;
    },
  },
  methods: {
    setActiveTab(tab) {
      this.selectedTab = tab;
      this.$emit("selected", tab);
    },
  },
};
</script>

<style>
.mz-tabs {
  display: flex;
  align-items: center;
  max-width: 100%;
  width: fit-content;
  background: var(--mz-grey-1);
  padding: 0.5rem;
  border-radius: 0.75rem;
  overflow-x: auto;
}
.mz-tabs.mz-tabs--auto {
  width: 100%;
  justify-content: space-between;
}
.mz-tabs .mz-tabs__item {
  display: inline-block;
  font-size: 0.75rem;
  text-decoration: none;
  color: var(--mz-dark);
  padding: 0.78rem 1.2rem;
  transition: background 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  margin: 0 4px;
  width: auto;
  min-width: fit-content;
}
.mz-tabs .mz-tabs__item.mz-tabs__item--active {
  background: var(--mz-primary);
  color: var(--mz-white);
  font-weight: var(--mz-fw-semibold);
  border-radius: 4px;
}
@media screen and (max-width: 1200px) {
  .mz-tabs {
    width: 100% !important;
  }
  .mz-tabs .mz-tabs__item {
    min-width: auto;
    width: 100%;
    font-size: 10px !important;
    text-align: center;
  }
}
</style>
