<template>
  <div class="d-flex align-items-center justify-content-center">
    <a
      href="javascript:void(0)"
      class="text-decoration-none mz-text--success-dark mz-text__body--22"
      @click="confirmAction"
    >
      <i class="bi bi-check-circle-fill"></i>
    </a>
    <div class="mx-2"></div>
    <a
      href="javascript:void(0)"
      class="text-decoration-none mz-text--danger mz-text__body--22"
      @click="cancelAction"
    >
      <i class="bi bi-x-circle-fill"></i>
    </a>
  </div>
</template>

<script>
export default {
  name: "ActionConfirmation",
  props: {
    dataToProcess: {
      type: Object,
      required: true,
    },
  },
  methods: {
    confirmAction() {
      this.$emit("confirm", this.dataToProcess);
    },
    cancelAction() {
      this.$emit("cancel", this.dataToProcess);
    },
  },
};
</script>

<style></style>
