<template>
  <div class="star-rating">
    <label
      class="star-rating__star"
      v-for="rating in ratings"
      :key="rating"
      :class="{
        'is-selected': value >= rating && value != null,
        'is-disabled': disabled,
      }"
    >
      <input
        class="star-rating__checkbox"
        type="radio"
        :value="rating"
        :name="name"
        :disabled="disabled"
      />
      ★
    </label>
  </div>
</template>

<script>
export default {
  name: "StarRating",
  props: {
    name: String,
    value: null,
    id: String,
    disabled: Boolean,
    required: Boolean,
  },

  data() {
    return {
      tempValue: null,
      ratings: [1, 2, 3, 4, 5],
    };
  },

  methods: {
    starOver(index) {
      if (!this.disabled) {
        this.tempValue = this.value;
        this.value = index;
      }
    },

    starOut() {
      if (!this.disabled) {
        this.value = this.tempValue;
      }
    },

    set(value) {
      if (!this.disabled) {
        this.tempValue = value;
        this.value = value;
      }
    },
  },
};
</script>

<style>
.visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.star-rating__star {
  display: inline-block;
  padding: 3px;
  vertical-align: middle;
  line-height: 1;
  font-size: 0.8125rem;
  color: #ababab;
  transition: color 0.2s ease-out;
}

.star-rating__star.is-selected {
  color: #ffd700;
}

.star-rating__star.is-disabled:hover {
  cursor: default;
}

.star-rating__checkbox {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
</style>
