<template>
  <nav v-if="totalPages > 1" class="d-flex justify-content-end mz-pagination">
    <ul class="pagination">
      <li class="page-item" :class="{ disabled: currentPage === 1 }">
        <button
          class="page-link"
          @click="previousPage"
          :disabled="currentPage === 1"
        >
          <i class="bi bi-chevron-left"></i>
        </button>
      </li>
      <li
        v-for="page in pageList"
        :key="page"
        class="page-item"
        :class="{
          active: currentPage === page,
          'disabled-page': page === '...',
        }"
      >
        <button class="page-link" @click="goToPage(page)">{{ page }}</button>
      </li>
      <li class="page-item" :class="{ disabled: currentPage === totalPages }">
        <button
          class="page-link"
          @click="nextPage"
          :disabled="currentPage === totalPages"
        >
          <i class="bi bi-chevron-right"></i>
        </button>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    pagination: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.updateQueryParam();
  },
  computed: {
    totalPages() {
      return this.pagination.totalPages;
    },
    currentPage() {
      return this.pagination.page;
    },
    pageList() {
      const pageNumbers = [];
      const maxVisiblePages = 2;

      if (this.totalPages <= maxVisiblePages * 2 + 1) {
        pageNumbers.push(
          ...Array.from({ length: this.totalPages }, (_, i) => i + 1)
        );
      } else {
        const currentPage = this.currentPage;
        const lastPage = this.totalPages;

        if (currentPage <= maxVisiblePages) {
          pageNumbers.push(
            ...Array.from({ length: maxVisiblePages * 2 + 1 }, (_, i) => i + 1)
          );
        } else if (currentPage >= lastPage - maxVisiblePages) {
          pageNumbers.push(
            ...Array.from(
              { length: maxVisiblePages * 2 + 1 },
              (_, i) => lastPage - maxVisiblePages * 2 + i
            )
          );
        } else {
          pageNumbers.push(
            ...Array.from(
              { length: maxVisiblePages * 2 + 1 },
              (_, i) => currentPage - maxVisiblePages + i
            )
          );
        }
      }

      return pageNumbers;
    },
  },

  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.$emit("page-change", this.currentPage - 1);
        this.scrollToTop();
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.$emit("page-change", this.currentPage + 1);
        this.scrollToTop();
      }
    },
    goToPage(page) {
      if (page === this.currentPage) return;
      this.$emit("page-change", page);
      this.scrollToTop();
    },
    updateQueryParam() {
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set("page", this.currentPage);
      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}?${queryParams}`
      );
    },
  },
  watch: {
    currentPage: "updateQueryParam",
  },
};
</script>
<style>
.mz-pagination .page-item {
  margin: 0.4rem;
}
.mz-pagination .page-item .page-link {
  padding: 8px !important;
  font-size: 14px;
  height: 23px;
  min-width: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px !important;
  background: var(--mz-grey-1) !important;
  color: var(--mz-black) !important;
  border: unset !important;
  box-shadow: unset !important;
}
.mz-pagination .page-item.active .page-link {
  background: var(--mz-primary) !important;
  color: var(--mz-white) !important;
}
</style>
