import axiosService from "@/services/axiosService";
import router from "@/router";
const state = {};

const getters = {};

const mutations = {};

const actions = {
  async getDevices({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(
        `/cs/security/devices?page=${payload.page || ""}&limit=${
          payload.limit || ""
        }`
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getNetworkStats({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/cs/security/netstats`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
