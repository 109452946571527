<template>
  <div class="text-center p-5 mz-bg--grey-1 mz-radius my-4">
    <h1 class="mz-text__h1 mz-text--grey-2 mb-5"><i class="bi bi-box2"></i></h1>
    <h3 class="mz-text__h4 mz-text--dark opacity-75">{{ title }}</h3>
    <p class="mz-text__body--14 mz-text--dark opacity-50">{{ description }}</p>
  </div>
</template>

<script>
export default {
  name: "EmptyState",
  props: {
    title: {
      type: String,
      default: "Nothing here",
    },
    description: {
      type: String,
      default: "There is no data to display",
    },
  },
};
</script>

<style></style>
