import axiosService from "@/services/axiosService";
import router from "@/router";
const state = {};

const getters = {};

const mutations = {};

const actions = {
  async getAllReferrer({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(
        `/cs/referrer?page=${payload.page || ""}&limit=${
          payload.limit || ""
        }&search=${payload?.search || ""}&period=${payload?.period || ""}`
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getSingleReferrer({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/referrer/${payload}`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async updateRefCode({ commit, state }, payload) {
    try {
      const { data } = await axiosService.put(`/cs/referrer/code`, payload);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getReferrerFees({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/referrer/settings`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async setupRefFees({ commit, state }, payload) {
    try {
      const { data } = await axiosService.put(`/referrer/settings`, payload);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getRefWatchlist({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(
        `/cs/referrer?page=${payload.page || ""}&limit=${payload.limit || ""}
        }&${new URLSearchParams(payload).toString()}`
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getNewRefWatchlist({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(
        `/cs/referrer?page=${payload.page || ""}&limit=${
          payload.limit || ""
        }&${new URLSearchParams(payload).toString()}`
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getGeoTracking({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/cs/referrer/by-country`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getReferrerOverview({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/referrer/overview`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
