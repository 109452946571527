<template>
  <div
    class="mz-modal"
    :class="{
      'mz-modal--center': position == 'center',
    }"
  >
    <div
      class="mz-modal__content"
      :class="{
        'mz-modal__content--large': contentSize == 'large',
        'mz-modal__content--medium': contentSize == 'medium',
        'mz-modal__content--small': contentSize == 'small',
        'mz-modal__content--smaller': contentSize == 'smaller',
      }"
    >
      <div
        class="mz-modal__content-controls d-flex justify-content-center flex-column"
        :class="{
          'align-items-center': alignment == 'center',
          'align-items-start': alignment == 'start',
        }"
      >
        <div
          class="d-flex my-2"
          v-if="showLogo"
          :class="{
            'justify-content-center': alignment == 'center',
            'justify-content-start': alignment == 'start',
          }"
        >
          <img src="@/assets/brand/logo.svg" alt="" class="mz-logo" />
        </div>
        <div class="w-100">
          <div
            class="mz-text__h1 mz-text--bold mz-text--dark"
            :class="{
              'text-center': alignment == 'center',
              'text-start': alignment == 'start',
            }"
          >
            {{ modalTitle }}
          </div>
          <div class="col-lg-9 mx-auto text-center">
            <p
              class="mz-text__body--14 mz-text--dark opacity-75"
              v-if="modalDescription"
            >
              {{ modalDescription }}
            </p>
          </div>
        </div>
        <a
          v-if="hasClose"
          href="javascript:void(0)"
          class="mz-modal__close"
          @click="emitClose"
        >
          <i class="bi bi-x-lg"></i>
        </a>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalWrapper",
  props: {
    hasClose: {
      type: Boolean,
      default: true,
    },
    showLogo: {
      type: Boolean,
      default: true,
    },
    contentSize: {
      type: String,
      default: "medium",
    },
    modalTitle: {
      type: String,
      default: "",
    },
    alignment: {
      type: String,
      default: "center",
    },
    modalDescription: {
      type: String,
    },
    position: {
      type: String,
      default: "top",
    },
  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.mz-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  top: 0;
  left: 0;
  overflow-y: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 4rem 1rem;
  background: rgba(0, 0, 0, 0.7);
  transform: scale(1);
  transition: 0.2s ease-in-out;
}
.mz-modal.mz-modal--center {
  align-items: center;
}
.mz-modal .mz-modal__content {
  position: relative;
  height: auto;
  width: 100%;
  max-width: 500px;
  background: var(--mz-white);
  z-index: 10;
  border-radius: 20px;
  padding: 2rem;
}
.mz-modal .mz-modal__content.mz-modal__content--large {
  max-width: 1200px;
}
.mz-modal .mz-modal__content.mz-modal__content--medium {
  max-width: 900px;
}
.mz-modal .mz-modal__content.mz-modal__content--small {
  max-width: 600px;
}
.mz-modal .mz-modal__content.mz-modal__content--smaller {
  max-width: 450px;
}
.mz-modal__content-controls {
  position: relative;
  margin-bottom: 1rem;
}
.mz-modal .mz-modal__close {
  position: absolute;
  top: -1rem;
  right: -1rem;
  color: var(--mz-grey-2);
  font-size: 1rem;
  opacity: 0.5;
  transition: 0.5s ease-in-out;
}
.mz-modal .mz-modal__close:hover {
  opacity: 1;
}
</style>
