import axiosService from "@/services/axiosService";
import router from "@/router";
const state = {};

const getters = {};

const mutations = {};

const actions = {
  async getCards({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(
        `/cs/card?page=${payload.page || ""}&limit=${
          payload.limit || ""
        }&type=${payload.type || ""}&isFronzen=${payload.isFronzen || false}`
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getHighlights({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/cs/card/highlights`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async freezeCard({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/cs/card/freeze`, payload);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getPopularFeatures({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/cs/card/popular-features`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async freezeCard({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/cs/card/freeze`, payload);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
