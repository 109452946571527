import axios from "axios";
import store from "@/store";
import router from "@/router";
import { API_BASE } from "@/config";
const axiosService = axios.create({
  baseURL: API_BASE,
});

axiosService.interceptors.request.use(
  (config) => {
    const { token } = store?.state?.auth || {};
    if (token && token?.token) {
      config.headers["Authorization"] = `Bearer ${token.token}`;
    }

    // if (refreshToken) {
    //   config.headers["x-refresh"] = refreshToken;
    // }

    return config;
  },
  (error) => Promise.reject(error)
);

axiosService.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status) {
      const status = error.response.status;

      if (status === 403 || status === 401) {
        router.push({
          path: "/",
        });
      }
    }

    return Promise.reject(error);
  }
);

export default axiosService;
