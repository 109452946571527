const ranks = [
  {
    rankName: "Community Leader",
    rank: 1,
    rankIcon: require("@/assets/icons/rank_1.svg"),
  },
  {
    rankName: "Morizon Agent T1",
    rank: 2,
    rankIcon: require("@/assets/icons/rank_2.svg"),
  },
  {
    rankName: "Morizon Agent T2",
    rank: 3,
    rankIcon: require("@/assets/icons/rank_3.svg"),
  },
];

export default ranks;
