<template>
  <div>
    <header class="mz-dashboard-header container-fluid">
      <div class="row">
        <div
          class="col-lg-5 d-flex align-items-center justify-content-center mz-dashboard-header__section mz-dashboard-header__section-left"
        >
          <div class="col-xl-9 mx-auto p-0">
            <slot></slot>
          </div>
        </div>
        <div
          class="col-lg-7 mz-dashboard-header__section row align-items-center"
        >
          <div class="col-lg-8">
            <HeaderActivityCounter />
          </div>
          <div class="col-lg-4 text-end">
            <DashboardHeaderInfo />
          </div>
        </div>
      </div>
    </header>
    <header class="mz-mobile-header align-items-center justify-content-between">
      <div class="d-flex align-items-center">
        <a
          href="javascript:void(0)"
          class="text-decoration-none mz-text__body--24 mz-text--white me-4"
          @click="toggleSidebar"
        >
          <span class="material-symbols-outlined"> menu </span>
        </a>
        <img
          src="@/assets/brand/admin_logo.svg"
          class="mz-mobile-header__logo"
          alt=""
        />
      </div>
      <DashboardHeaderInfo iconOnly />
    </header>
  </div>
</template>

<script>
import HeaderActivityCounter from "./HeaderActivityCounter.vue";
import DashboardHeaderInfo from "./DashboardHeaderInfo.vue";
import { eventBus } from "@/utils/eventBus";
export default {
  name: "DashboardHeader",
  components: {
    HeaderActivityCounter,
    DashboardHeaderInfo,
  },
  data() {
    return {
      sidebarOpen: false,
    };
  },
  methods: {
    async toggleSidebar() {
      this.sidebarOpen = !this.sidebarOpen;
      eventBus.emit("toggleSidebar", this.sidebarOpen);
      this.sidebarOpen = false;
    },
  },
};
</script>

<style>
.mz-dashboard-header {
  height: var(--mz-header-height);
  background: var(--mz-primary-dark);
  width: 100%;
  /* position: fixed; */
}
.mz-dashboard-header .row,
.mz-dashboard-header .mz-dashboard-header__section {
  position: relative;
  height: 100%;
}
.mz-dashboard-header
  .mz-dashboard-header__section.mz-dashboard-header__section-left {
  border-right: 3px solid var(--mz-white-muted-1);
}
.mz-mobile-header {
  display: none;
  background: var(--mz-primary-dark);
  padding: 1rem;
}
.mz-mobile-header .mz-mobile-header__logo {
  max-width: 120px;
}
@media screen and (max-width: 1280px) {
  .mz-dashboard-header {
    display: none;
  }
  .mz-mobile-header {
    display: flex;
  }
}
</style>
