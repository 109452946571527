import { createRouter, createWebHistory } from "vue-router";
import { routes } from "./routes";
import store from "@/store";
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});
router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.state && store.state.auth && !store.state.auth.isAuthenticated) {
      next({
        name: "Login",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});
export default router;
