<template>
  <div class="dropdown mz-dropdown me-1">
    <button
      class="mz-button mz-button--primary dropdown-toggle w-100 justify-content-between"
      type="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      :class="{
        'mz-button--plain': isPlain,
        'mz-button--small': size === 'small',
        'mz-button--smaller': size === 'smaller',
      }"
    >
      <span>
        {{ (selectedItem && selectedItem.label) || "Choose Option" }}</span
      >
      <i class="bi bi-caret-down-fill ms-2"></i>
    </button>
    <ul class="dropdown-menu mz-dropdown-menu dropdown-menu-start w-100">
      <li v-for="(item, index) in options" :key="index">
        <a
          class="dropdown-item d-inline-flex align-items-center justify-content-between"
          href="javascript:void(0)"
          @click="setItem(item)"
        >
          <span class="break--word d-inline-block"> {{ item.label }} </span>
          <i
            class="bi bi-check2"
            v-if="selectedItem && selectedItem.value === item.value"
          ></i>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "DropdownMenu",
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    isPlain: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "default",
    },
  },
  data() {
    return {
      selectedItem: null,
    };
  },
  methods: {
    setItem(item) {
      if (this.selectedItem && this.selectedItem.value === item.value) return;
      this.selectedItem = item;
      this.$emit("itemSelected", item);
    },
  },
};
</script>

<style></style>
