const acountTypes = [
  {
    name: "Personal Account",
    value: "personal",
    iconPath: require("@/assets/icons/account/personal.png"),
  },
  {
    name: "Gold Account",
    value: "gold",
    iconPath: require("@/assets/icons/account/gold.png"),
  },
  {
    name: "Business Account",
    value: "business",
    iconPath: require("@/assets/icons/account/business.png"),
  },
];
export default acountTypes;
