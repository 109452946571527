<template>
  <div>
    <modal-wrapper v-if="pickerIsOpen" contentSize="smaller" @close="handleClose">
      <VueDatePicker range :enable-time-picker="false" @update:model-value="handleDate" @closed="handleClose"
        v-model="date" ref="datepicker"></VueDatePicker>
    </modal-wrapper>
    <div class="dropdown mz-dropdown">
      <button class="mz-button mz-button--danger-dark mz-button--smaller mz-button--plain" type="button"
        @click="openDatePicker">
        <i class="bi bi-calendar3 me-2"></i>
        {{
      date.length > 0
        ? `${formatDateOnly(date[0], "YYYY-MM-DD")} - ${formatDateOnly(
          date[1],
          "YYYY-MM-DD"
        )}`
        : "Custom Period"
    }}
      </button>
    </div>
  </div>
</template>

<script>
import { formatDateOnly } from "@/helpers";
export default {
  name: "CustomDatePeriod",
  data() {
    return {
      date: [],
      pickerIsOpen: false,
    };
  },
  mounted() {
    // const startDate = new Date();
    // const endDate = new Date(new Date().setDate(startDate.getDate() + 7));
    // this.date = [startDate, endDate];
  },
  methods: {
    formatDateOnly,
    async openDatePicker() {
      this.pickerIsOpen = true;
      await this.$nextTick();
      this.$refs.datepicker.openMenu();
    },
    handleClose() {
      this.pickerIsOpen = false;
    },
    async handleDate(date) {
      const startDate = formatDateOnly(date[0], "YYYY-MM-DD");
      const endDate = formatDateOnly(date[1], "YYYY-MM-DD");
      this.$emit("dateChanged", {
        startDate: new Date(startDate).getTime(),
        endDate: new Date(endDate).getTime(),
      });
      this.$nextTick(() => {
        this.handleClose();
      });
    },
  },
};
</script>

<style></style>
