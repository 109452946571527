export function convertArrayToCSVBlob(data, mimeType = 'text/csv') {
    if (!data.length) {
        throw new Error("Empty data array.");
    }

    // Extract headers (keys) from the first object in the array
    const headers = Object.keys(data[0]);

    // Create CSV rows
    const csvRows = data.map(row =>
        headers.map(header => {
            let value = row[header];


            // If the value is an object (and not null), convert it to a JSON string
            if (value && typeof value === 'object') {
                value = JSON.stringify(value);
            }


            // Escape double quotes by doubling them
            return `"${String(value).replace(/"/g, '""')}"`;
        }).join(',')
    );

    // Combine headers and rows into a single CSV string
    const csvString = `${headers.join(',')}\n${csvRows.join('\n')}`;

    // Create a Blob from the CSV string
    const blob = new Blob([csvString], { type: mimeType });

    return blob;
}
