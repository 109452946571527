<template>
  <div class="row gx-1">
    <div class="col-6">
      <div class="dropdown mz-dropdown">
        <button
          class="mz-button mz-button--white dropdown-toggle mz-shadow-4 justify-content-between w-100 mz-button--auto mz-text__body--12 mz-text--dark"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {{ selectedMonth }}
          <i class="bi bi-caret-down-fill ms-2 opacity-50"></i>
        </button>
        <ul class="dropdown-menu mz-dropdown-menu dropdown-menu-start">
          <li v-for="(month, index) in months" :key="index + 1">
            <a
              class="dropdown-item"
              href="javascript:void(0)"
              @click="selectMonth(month)"
            >
              {{ month }}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-3">
      <div class="dropdown mz-dropdown">
        <button
          class="mz-button mz-button--white dropdown-toggle mz-shadow-4 justify-content-between w-100 mz-button--auto mz-text__body--12 mz-text--dark"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {{ selectedDay }}
          <i class="bi bi-caret-down-fill ms-2 opacity-50"></i>
        </button>
        <ul class="dropdown-menu mz-dropdown-menu dropdown-menu-end">
          <li
            v-for="day in getDaysInMonth(selectedMonth, selectedYear)"
            :key="day"
          >
            <a
              class="dropdown-item"
              href="javascript:void(0)"
              @click="selectDay(day)"
            >
              {{ day }}
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="col-3">
      <div class="dropdown mz-dropdown">
        <button
          class="mz-button mz-button--white dropdown-toggle mz-shadow-4 justify-content-between w-100 mz-button--auto mz-text__body--12 mz-text--dark"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {{ selectedYear }}
          <i class="bi bi-caret-down-fill ms-2 opacity-50"></i>
        </button>
        <ul class="dropdown-menu mz-dropdown-menu dropdown-menu-end">
          <li v-for="year in years" :key="year">
            <a
              class="dropdown-item"
              href="javascript:void(0)"
              @click="selectYear(year)"
            >
              {{ year }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DobPicker",
  data() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return {
      months,
      years: Array.from({ length: 100 }, (_, i) => currentYear - i),
      selectedMonth: months[currentDate.getMonth()],
      selectedYear: currentYear.toString(),
      selectedDay: currentDate.getDate().toString(),
    };
  },
  mounted() {
    this.selectDay(this.selectedDay);
    this.selectMonth(this.selectedMonth);
    this.selectYear(this.selectedYear);
  },
  methods: {
    emitDateOfBirth() {
      this.$emit("dateOfBirthSelected", {
        day: this.selectedDay,
        month: this.selectedMonth,
        year: this.selectedYear,
      });
    },
    selectDay(day) {
      this.selectedDay = day.toString();
      this.$emit("daySelected", this.selectedDay);
      // this.emitDateOfBirth();
    },
    selectMonth(month) {
      this.selectedMonth = month;
      this.$emit("monthSelected", this.selectedMonth);
      // this.emitDateOfBirth();
    },
    selectYear(year) {
      this.selectedYear = year.toString();
      this.$emit("yearSelected", this.selectedYear);
      // this.emitDateOfBirth();
    },
    getDaysInMonth(month, year) {
      const daysInMonth = new Date(
        year,
        this.months.indexOf(month) + 1,
        0
      ).getDate();
      return Array.from({ length: daysInMonth }, (_, i) => i + 1);
    },
  },
};
</script>

<style>
/* Add your custom styles here if needed */
</style>
