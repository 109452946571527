import axiosService from "@/services/axiosService";
import router from "@/router";
const state = {};

const getters = {};

const mutations = {};

const actions = {
  async getTickets({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(
        `/cs/ticket?page=${payload.page || ""}&limit=${
          payload.limit || ""
        }&status=${payload.status || ""}`
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async searchTickets({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(
        `/cs/ticket/search?page=${payload.page || ""}&limit=${
          payload.limit || ""
        }&value=${payload.value || ""}`
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getTicketConversation({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(
        `/admin-conversations/details/${payload.id}`
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async sendChatMessage({ commit, state }, payload) {
    try {
      const { data } = await axiosService.post(`/admin-conversations`, payload);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async updateTicketStatus({ commit, state }, payload) {
    try {
      const { data } = await axiosService.put(`/cs/ticket/${payload.id}`, {
        status: payload.status,
      });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async createImprovementNote({ commit, state }, payload) {
    try {
      const { data } = await axiosService.post(`/cs/improvement-note`, payload);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getImprovementNotes({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(
        `/cs/improvement-note?page=${payload.page || ""}&limit=${
          payload.limit || ""
        }&status=${payload.status || ""}`
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getFeedbacks({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(
        `/cs/feedback?page=${payload.page || ""}&limit=${
          payload.limit || ""
        }&status=${payload.status || ""}&deviceType=${payload.deviceType || ""}`
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getSingleFeedback({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/cs/feedback/${payload.id}`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getQuickResponses({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(
        `/cs/quick-response?page=${payload.page || ""}&limit=${
          payload.limit || ""
        }`
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async createQuickResponse({ commit, state }, payload) {
    try {
      const { data } = await axiosService.post(`/cs/quick-response`, payload);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async editQuickResponse({ commit, state }, { target, payload }) {
    try {
      const { data } = await axiosService.put(
        `/cs/quick-response/${target}`,
        payload
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async deleteQuickResponse({ commit, state }, { target, payload }) {
    try {
      const { data } = await axiosService.delete(
        `/cs/quick-response/${target}`,
        payload
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
