<template>
  <main
    class="mz-auth-wrapper d-flex align-items-center justify-content-center container-fluid"
  >
    <img
      src="@/assets/img/auth_pattern_1.png"
      class="mz-auth-wrapper__img mz-auth-wrapper__img--top"
      alt=""
    />
    <img
      src="@/assets/img/auth_pattern_2.png"
      class="mz-auth-wrapper__img mz-auth-wrapper__img--bottom"
      alt=""
    />
    <div
      class="mz-auth-wrapper__content d-flex align-items-center justify-content-center w-100"
    >
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
  </main>
</template>

<script>
import { useHead } from "unhead";
export default {
  name: "AuthLayout",
  setup() {
    useHead({
      title: "Get Started",
    });
  },
};
</script>

<style scoped>
.mz-auth-wrapper {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
}
.mz-auth-wrapper .mz-auth-wrapper__content {
  position: relative;
  z-index: 3;
}
.mz-auth-wrapper__img {
  display: block;
  position: absolute;
  max-width: 600px;
  z-index: 1;
}
.mz-auth-wrapper__img.mz-auth-wrapper__img--top {
  top: 0;
  left: 0;
}
.mz-auth-wrapper__img.mz-auth-wrapper__img--bottom {
  bottom: 0;
  right: 0;
}
</style>
