import axiosService from "@/services/axiosService";
import router from "@/router";
const state = {};

const getters = {};

const mutations = {};

const actions = {
  async serverInfo({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(
        `/serverInfo`
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getAllTransactions({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(
        `/cs/transaction?page=${payload.page || ""}&limit=${payload.limit || ""
        }&category=${payload.category}&startDate=${payload.startDate || ""
        }&endDate=${payload.endDate || ""}`
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async holdTransaction({ commit, state }, payload) {
    try {
      const { data } = await axiosService.put(
        `/transactions/${payload.id}/hold`,
        {
          isOnHold: !payload.isOnHold,
        }
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async exportTransactions({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(
        `/transactions/export?startDate=${payload.startDate || ""}&endDate=${payload.endDate || ""
        }`
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getSingleTransaction({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/transactions/${payload.id}`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getTransactionAnalytics({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/cs/transaction/chart`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getProfitHighlights({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/cs/transaction/highlights`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getTransactionOverview({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/cs/transaction/overview`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
