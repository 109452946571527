import AuthLayout from "@/layouts/AuthLayout";
export const AuthRoutes = {
  path: "/",
  redirect: "/auth/login",
  component: AuthLayout,
  children: [
    {
      path: "/auth/login",
      name: "Login",
      component: () => import("@/views/auth/Login.vue"),
    },
    {
      path: "/auth/login/twofa",
      name: "TwoFa",
      component: () => import("@/views/auth/TwoFa.vue"),
    },
    {
      path: "/auth/recovery",
      name: "Recovery",
      component: () => import("@/views/auth/Recovery.vue"),
    },
  ],
};
