import axiosService from "@/services/axiosService";
import router from "@/router";
const state = {
  user: null,
  isAuthenticated: false,
  token: null,
};

const getters = {
  isAuthenticated: (state) => state.isAuthenticated,
  getUser: (state) => state.user,
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setToken(state, token) {
    state.token = token;
  },
  setIsAuthenticated(state, isAuthenticated) {
    state.isAuthenticated = isAuthenticated;
  },
};

const actions = {
  async login({ commit, state }, credentials) {
    try {
      const { data } = await axiosService.post("/auth/login", credentials);
      commit("setUser", data.data);
      commit("setToken", data.data.token);
      commit("setIsAuthenticated", true);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  endSession({ commit, state }) {
    commit("setIsAuthenticated"), false;
    router.push("/");
    setTimeout(() => {
      commit("setUser"), null;
      commit("setToken"), null;
    }, 1000);
  },
  async registerSuperAdmin({ commit, state }, credentials) {
    try {
      const { data } = await axiosService.post(
        "/auth/register/admin",
        credentials
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async checkForSuperAdmin({ commit, state }) {
    try {
      const { data } = await axiosService.get("/adminExist");
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async requestPasswordReset({ commit, state }, credentials) {
    try {
      const { data } = await axiosService.post(
        "/auth/password/reset",
        credentials
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getRecoveryQuestions({ commit, state }) {
    try {
      const { data } = await axiosService.get("/auth/recovery-questions");
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
