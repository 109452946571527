<template>
  <form @submit.prevent="initSearch">
    <div class="d-flex align-items-center my-4 my-xl-0">
      <div class="input-group mz-input-group mz-input-group--smaller me-2">
        <input
          type="text"
          class="form-control ap-form-input"
          placeholder="Search"
          v-model="searchQuery"
        />
        <div>
          <div class="dropdown mz-dropdown">
            <button
              class="mz-button mz-button--primary mz-button--smaller dropdown-toggle text-capitalize"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span class="mz-text__body--12">{{
                shortenString(selectedSearchOption, 4)
              }}</span>
              <i class="bi bi-caret-down-fill ms-2"></i>
            </button>
            <ul class="dropdown-menu mz-dropdown-menu dropdown-menu-end">
              <li v-for="(item, index) in searchOptions" :key="index">
                <a
                  class="dropdown-item text-capitalize d-flex align-items-center justify-content-between"
                  href="javascript:void(0)"
                  @click="setSearchOption(item)"
                >
                  {{ item }}
                  <i
                    class="bi bi-check"
                    v-if="item === selectedSearchOption"
                  ></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <button
        class="mz-button mz-button--primary mz-button--smaller"
        @click="initSearch"
        :disabled="isLoading"
      >
        Search
      </button>
    </div>
  </form>
</template>

<script>
import { shortenString } from "@/helpers";
export default {
  name: "TableSearchControl",
  props: {
    searchOptions: {
      type: Array,
      required: true,
    },
    defaultOption: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedSearchOption: "",
      searchQuery: "",
    };
  },
  created() {
    this.selectedSearchOption = this.defaultOption;
  },
  methods: {
    shortenString,
    setSearchOption(item) {
      this.selectedSearchOption = item;
    },
    initSearch() {
      this.$emit("initSearch", {
        searchQuery: this.searchQuery,
        searchOption: this.selectedSearchOption,
      });
    },
  },
  watch: {
    searchQuery(newVal) {
      if (newVal.trim() === "") {
        this.initSearch();
      }
    },
  },
};
</script>

<style></style>
