<template>
  <div>
    <div class="dropdown mz-dropdown">
      <button
        class="mz-button mz-button--white dropdown-toggle mz-shadow-4 justify-content-between w-100 mz-button--auto mz-text__body--12 mz-text--dark"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {{ selectedGender || "Choose Option" }}
        <i class="bi bi-caret-down-fill ms-2 opacity-50"></i>
      </button>
      <ul class="dropdown-menu mz-dropdown-menu dropdown-menu-start">
        <li v-for="(gender, index) in genders" :key="index + 1">
          <a
            class="dropdown-item"
            href="javascript:void(0)"
            @click="changeGender(gender)"
          >
            {{ gender }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "GenderPicker",
  data() {
    return {
      genders: ["Male", "Female", "Prefer not to say"],
      selectedGender: "",
    };
  },
  methods: {
    changeGender(gender) {
      this.selectedGender = gender;
      this.$emit("genderSelected", gender);
    },
  },
};
</script>

<style></style>
