<template>
  <div class="d-flex align-items-center cursor--pointer" @click="handleCheck">
    <div class="mz-checkbox me-2">
      <i
        class="bi bi-check-lg"
        :style="{
          color: checkColor,
        }"
        v-if="checked"
      ></i>
    </div>

    <label class="mz-text__body--12 mz-text--dark cursor--pointer">
      <slot></slot>
    </label>
  </div>
</template>

<script>
export default {
  name: "Checkbox",
  props: {
    checkColor: {
      type: String,
      default: "#cd44f3",
    },
    isChecked: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checked: this.isChecked,
    };
  },
  methods: {
    handleCheck() {
      if (this.isDisabled) return;
      this.checked = !this.checked;
      this.$emit("checkedEvent", this.checked);
    },
  },
};
</script>

<style>
.mz-checkbox {
  height: 22px;
  width: 22px;
  border-radius: 4px;
  background: var(--mz-grey-1);
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  font-size: 1.2rem;
  border: 1px solid var(--mz-grey-2);
}
</style>
