<template>
  <div class="text-center p-5 mz-bg--grey-1 mz-radius my-4">
    <h1 class="mz-text__h1 mz-text--grey-2 mb-5"><i class="bi bi-bug"></i></h1>
    <h3 class="mz-text__h4 mz-text--dark opacity-75">{{ title }}</h3>
    <p class="mz-text__body--14 mz-text--dark opacity-50">
      {{ description }}.
      <a
        href="javascript:void(0)"
        @click="emitRetry"
        class="mz-text--primary mz-text--medium text-decoration-none"
        >Try again</a
      >
    </p>
  </div>
</template>

<script>
export default {
  name: "ErrorState",
  props: {
    title: {
      type: String,
      default: "An error occurred",
    },
    description: {
      type: String,
      default: "Something went wrong with your request",
    },
  },

  methods: {
    emitRetry() {
      this.$emit("retry");
    },
  },
};
</script>

<style></style>
