<template>
  <div
    class="mz-loader"
    :class="{
      'mz-loader--large': isLarge,
      'mz-loader--center': isCenter,
    }"
  ></div>
</template>

<script>
export default {
  name: "Spinner",
  props: {
    isLarge: {
      type: Boolean,
      default: false,
    },
    isCenter: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
.mz-loader {
  border: 2px dashed #f3f3f3;
  border-top: 1px dashed var(--mz-primary);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 750ms linear infinite;
  margin: 0 8px;
}
.mz-loader.mz-loader--large {
  width: 60px !important;
  height: 60px !important;
  border-width: 2px;
}
.mz-loader.mz-loader--center {
  margin: 2rem auto;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
