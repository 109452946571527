<template>
  <div>
    <Spinner v-if="isLoading" :isLarge="true" :isCenter="true" />
    <div v-if="!isLoading && permissions">
      <div class="p-4 mz-border mz-border--grey-1 mz-radius">
        <div class="row gx-0">
          <div class="col-6">
            <div
              class="d-flex align-items-center justify-content-center flex-column"
            >
              <div
                class="px-2 py-3 w-100 text-center mz-bg--warning-light mz-text__body--14 mz-text--semibold mz-text--dark"
              >
                {{ getRoleData("superAdmin").name }}
              </div>
              <img
                :src="getRoleData('superAdmin').iconPath"
                class="mz-icon mz-icon--regular my-3"
                alt=""
              />
            </div>
            <div class="my-4">
              <div
                class="d-flex align-items-center my-4"
                v-for="(item, index) in superAdminPermissions"
                :key="index"
              >
                <Checkbox :isChecked="true" isDisabled />
                <div class="mx-2"></div>
                <div>
                  <div class="mz-text__body--14 mz-text--dark">
                    {{ item }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div
              class="d-flex align-items-center justify-content-center flex-column"
            >
              <div
                class="px-2 py-3 w-100 text-center mz-bg--primary-muted mz-text__body--14 mz-text--semibold mz-text--dark"
              >
                {{ getRoleData("admin").name }}
              </div>
              <img
                :src="getRoleData('admin').iconPath"
                class="mz-icon mz-icon--regular my-3"
                alt=""
              />
            </div>
            <div class="my-4">
              <div
                class="d-flex align-items-center my-4"
                v-for="(item, index) in adminPermissions"
                :key="index"
              >
                <Checkbox :isChecked="true" isDisabled />
                <div class="mx-2"></div>
                <div>
                  <div class="mz-text__body--14 mz-text--dark">
                    {{ item }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="d-flex align-items-center justify-content-between mt-5"
        v-if="showControls"
      >
        <div class="mz-text__body--14 mz-text--dark">
          <!-- Last Updated by Ronald Collins (You) -->
        </div>
        <div class="d-flex align-items-center">
          <button
            class="mz-button mz-button--info mz-button--plain"
            type="button"
          >
            <img
              src="@/assets/icons/clock.svg"
              class="mz-icon mz-icon--regular me-2"
              alt=""
            />
            History
          </button>
          <div class="mx-2"></div>
          <button
            class="mz-button mz-button--info mz-button--plain"
            type="button"
          >
            <img
              src="@/assets/icons/save.svg"
              class="mz-icon mz-icon--regular me-2"
              alt=""
            />
            Save and Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getRoleData } from "@/helpers";
export default {
  name: "PermissionControl",
  props: {
    showControls: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      permissions: null,
      isLoading: false,
    };
  },
  mounted() {
    this.getPermissions();
  },
  computed: {
    adminPermissions() {
      return (this.permissions && this.permissions.admin) || [];
    },
    superAdminPermissions() {
      return (this.permissions && this.permissions.superAdmin) || [];
    },
  },
  methods: {
    getRoleData,
    async getPermissions() {
      this.isLoading = true;
      try {
        const apiResponse = await this.$store.dispatch("fetchPermissions");
        this.permissions = apiResponse.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style></style>
