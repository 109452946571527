<template>
  <div>
    <div
      class="d-flex align-items-center justify-content-end"
      v-if="USER && USER?.admin"
    >
      <div class="mz-avatar mz-avatar--regular me-2">
        <img :src="USER?.admin?.displayPhoto" alt="" />
      </div>
      <div v-if="!iconOnly" class="d-flex align-items-center">
        <div class="text-start me-2">
          <div class="d-flex align-items-center">
            <div
              class="mz-text__body--12 mz-text--white mz-text--semibold text-capitalize"
            >
              {{ USER?.admin?.name?.last }} {{ USER?.admin?.name?.first }}
            </div>
          </div>
          <div class="d-flex align-items-center">
            <img
              :src="getRoleData(USER?.admin?.role).iconPath"
              class="mz-icon mz-icon--small me-1"
              alt=""
            />
            <span
              class="mz-text__body--9 mz-text--warning"
              :style="{
                color: getRoleData(USER?.admin?.role).colorCode,
              }"
            >
              {{ getRoleData(USER?.admin?.role).shortName }}
            </span>
          </div>
        </div>
        <div class="dropdown mz-dropdown">
          <a
            class="mz-dropdown__icon-toggle dropdown-toggle w-100 justify-content-between text-start"
            href="javascript:void(0)"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <div class="d-flex align-items-center">
              <img
                src="@/assets/icons/box-settings-primary.svg"
                class="mz-icon mz-icon--regular"
                alt=""
              />
            </div>
          </a>
          <ul class="dropdown-menu w-100" aria-labelledby="dropdownMenuButton1">
            <li>
              <a
                class="dropdown-item d-flex align-items-center justify-content-between mz-text--danger"
                href="javascript:void(0)"
                @click="endSession"
              >
                <span>End Session</span>
                <i class="bi bi-box-arrow-right"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getRoleData } from "@/helpers";
export default {
  name: "DashboardHeaderInfo",
  props: {
    iconOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      USER: "getUser",
    }),
  },
  methods: {
    getRoleData,
    endSession() {
      this.$store.dispatch("endSession");
    },
  },
};
</script>

<style></style>
