<template>
  <div>
    <div v-if="!isStandalone">
      <input type="file" @change="handleFileChange" v-if="!isLoading" />
      <div
        class="mz-upload-progress"
        :style="{
          width: `${progress}%`,
        }"
      ></div>
    </div>
    <div v-if="isStandalone" class="my-5">
      <div class="mz-upload-area">
        <input
          type="file"
          name=""
          id=""
          @change="handleFileChange"
          v-if="!isLoading"
        />
        <img
          src="@/assets/icons/file_attachment.svg"
          class="mz-icon mz-icon--regular"
          alt=""
        />
        <div class="mz-text__body--14 mz-text--dark text-center">
          {{ isLoading ? `${progress}%` : "Drop file here or Browse." }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosService from "@/services/axiosService";
import { ref } from "vue";
import { useToast } from "vue-toastification";
export default {
  name: "FileUpload",
  emits: ["fileUploaded"],
  props: {
    accepts: {
      type: Array,
      default: () => ["image/jpeg", "image/png", "application/zip"],
    },
    isStandalone: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const file = ref(null);
    const progress = ref(0);
    const isLoading = ref(false);
    const toast = useToast();
    const handleFileChange = async (event) => {
      const selectedFile = event.target.files[0];
      if (!selectedFile) {
        toast.error("No file selected");
        return;
      }
      const allowedTypes = [...props.accepts];
      if (!allowedTypes.includes(selectedFile.type)) {
        toast.error("Invalid file type");
        event.target.value = null;
        return;
      }
      const maxSizeInBytes = 5 * 1024 * 1024;
      if (selectedFile.size > maxSizeInBytes) {
        toast.error("File size exceeds 5MB limit.");
        event.target.value = null;
        return;
      }
      file.value = selectedFile;
      try {
        await uploadFile();
      } catch (error) {
        event.target.value = null;
      }
    };

    const uploadFile = async () => {
      if (!file.value) {
        toast.error("No file selected");
        return;
      }
      isLoading.value = true;
      try {
        const formData = new FormData();
        formData.append("file", file.value);

        const response = await axiosService.post("/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const percentage = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            progress.value = percentage;
          },
        });
        emit("fileUploaded", response.data);
        toast.success("File Uploaded!");
      } catch (error) {
        console.error("Upload error:", error);
        toast.error("An error occurred while uploading");
      } finally {
        progress.value = 0;
        isLoading.value = false;
      }
    };

    return { handleFileChange, uploadFile, progress, isLoading };
  },
};
</script>

<style>
.mz-upload-progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: var(--mz-primary);
  opacity: 0.3;
}
</style>
